import Mock from 'mockjs'

Mock.setup({
    timeout:'200-600'
})

const productList = {
    total: 21,
    pageSize: 10,
    list:
        [{
            id: 1,
            name: '产品1',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 2,
            name: '产品2',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 3,
            name: '产品3',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 4,
            name: '产品4',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 5,
            name: '产品5',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 6,
            name: '产品6',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 7,
            name: '产品7',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 8,
            name: '产品8',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 9,
            name: '产品9',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }, {
            id: 10,
            name: '产品10',
            connectType: 1,
            transportProtocol: 1,
            dataProtocol: 1,
            description: ''
        }]
};

const deviceList = Mock.mock({
    "data|10": [{
        'id|+1': 1,
        'productId|1-4':1
    }]
})

Mock.mock('/api/products', 'get', productList);

Mock.mock('/api/devices', 'get', deviceList);