import axios from '@/utils/request'

// 应用管理接口

// 分页查询
const all = params =>{
    return axios.post('/aiot-api-service/handler-api/get-all-handlers', {params: params})
}

export default {
    all
}