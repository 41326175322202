import axios from '@/utils/request'

// 应用管理接口

// 分页查询
const search = params =>{
    return axios.get('/aiot-api-service/apps', {params: params})
}

// 新增
const create = params =>{
    return axios.post('/aiot-api-service/apps', params)
}

// 查看详细
const show = id =>{
    return axios.get(`/aiot-api-service/apps/${id}`)
}

// 修改
const update = (id, params) =>{
    return axios.put(`/aiot-api-service/apps/${id}`, params)
}

// 查询所有列表
const all = params => {
    return axios.get('/aiot-api-service/apps/all', { params: params });
}

// 导出
const exports = params =>{
    return axios.get('/aiot-api-service/apps/export', {params: params})
}

// 导入
const imports = params =>{
    return axios.post('/aiot-api-service/apps/import', params)
}

// 删除
const destroy = id => {
    return axios.delete(`/aiot-api-service/apps/${id}`);
}

// 删除组应用授权
const cancelAuthorize = (id,groupId) => {
    return axios.delete(`/aiot-api-service/apps/${id}/group/${groupId}`);
}
// 添加组应用授权
const batchAuthorize = (id,params) => {
    return axios.post(`/aiot-api-service/apps/${id}/authorize-group`, params)
}

export default {
    search,
    create,
    show,
    update,
    all,
    exports,
    imports,
    destroy,
    cancelAuthorize,
    batchAuthorize
}