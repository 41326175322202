import axios from '@/utils/request'
import device from './device'
import app from './app'
import product from './product'
import auth from './auth'
import authorize from './authorize'
import handler from './handler'
import group from './group'
import firmware from './firmware'
import statistic from './statistic'

const request = (url, method, params) => {
    return axios.request({
        url: url,
        method: method,
        data: params
    })
}

export default {
    request,
    device,
    app,
    product,
    auth,
    authorize,
    handler,
    group,
    firmware,
    statistic
}