import axios from '@/utils/request'

// 分页查询
const getDeviceCount = () => {
    return axios.get('/aiot-api-service/home-statistics/stats-number')
}

const getGroupRank = (params) => {
    return axios.post('/aiot-api-service/home-statistics/stats-group-device-times', params)
}

const getDeviceRank = (params) => {
    return axios.post('/aiot-api-service/home-statistics/stats-device-times', params)
}

export default {
    getDeviceCount,
    getGroupRank,
    getDeviceRank
}
