import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        accessToken: null,
        user: null
    },
    getters: {
        authToken: () => {
            let accessToken = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null
            return accessToken
        }
    },
    actions: {
        AUTH_LOGIN(context, data) {
            return new Promise((resolve, reject) => {
                api.auth.login(data).then(response => {
                    context.commit("SAVE_ACCESS_TOKEN", response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            });
        }
    },
    mutations: {
        LOGOUT(state) {
            localStorage.removeItem('token');
            state.accessToken = null;
        },
        SAVE_ACCESS_TOKEN(state, accessToken) {
            localStorage.setItem('token', JSON.stringify(accessToken));
        },
        SAVE_CURRENT_USER(state, user) {
            state.user = user;
        }
    }
})

const accessToken = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null

store.state.accessToken = accessToken

export default store
