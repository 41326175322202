import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
            path: '/',
            component: () => import('@/views/index.vue'),
            meta: { requireAuth: true },
            children: [
                {
                    path: '/',
                    component: () => import('@/views/home/index.vue'),
                    meta: { requireAuth: true },

                },
                {
                    path: '/products',
                    component: () => import('@/views/product/index.vue'),
                    meta: { requireAuth: true },
                },
                {
                    path: '/devices',
                    component: () => import('@/views/device/index.vue'),
                    meta: { requireAuth: true },
                },                {
                    path: '/groups',
                    component: () => import('@/views/group/index.vue'),
                    meta: { requireAuth: true },
                },
                {
                    path: '/products',
                    component: () => import('@/views/product/index.vue'),
                    meta: { requireAuth: true },
                }, {
                    path: '/cameras',
                    component: () => import('@/views/camera/index.vue'),
                    meta: { requireAuth: true },
                },
                {
                    path: '/apps',
                    component: () => import('@/views/app/index.vue'),
                    meta: { requireAuth: true },
                },
                {
                    path: '/data',
                    component: () => import('@/views/data/index.vue'),
                    meta: { requireAuth: true },
                }
            ],
        }, {
            path: '/auth/login',
            name: 'login',
            component: () => import('@/views/auth/login')
        },
        {
            path: '/data/:id',
            component: () => import('@/views/data/details.vue'),
            meta: { requireAuth: true },
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
        const accessToken = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null
        if (accessToken) {
            next();
        } else {
            next({ name: 'login' });

        }
    } else {
        next();
    }
})

export default router
