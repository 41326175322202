import Axios from 'axios'
import router from '../router'
let baseURL = ''

if (process.env.NODE_ENV === 'development') {
    baseURL = "http://192.168.2.175:9100/";
    // baseURL = "https://api.aiot.gloryai.com/"; // 正式环境
}
else if (process.env.NODE_ENV === 'production') {
    // baseURL = "http://192.168.2.175:9100/"; // 测试环境
    baseURL = "https://api.aiot.gloryai.com/"; // 正式环境
}
else if (process.env.NODE_ENV === 'test') {
    baseURL = "http://192.168.2.175:9100/";
}

const axios = Axios.create({
    baseURL: baseURL
})

axios.interceptors.request.use(config => {

    const accessToken = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null

    if (accessToken) {
        config.headers.common['Authorization'] = accessToken.token_type + " " + accessToken.access_token;
    }
    return config;
})

axios.interceptors.response.use(response => {
    return response.data;
}, error => {
    switch (error.response.status) {
        case 401:
            router.replace('/auth/login');
            break;
        default:
            return Promise.reject(error)
    }
    return Promise.reject(error)
})

export default axios;


// const axios = new Axios.create({
//     baseURL: baseURL
// })

// axios.interceptors.request.use(config => {
//     return config;
// }, error => {
//     return Promise.reject(error)
// });

// axios.interceptors.response.use(response => {
//     if (response.status === 200){
//         return response.data
//     }else{
//         return Promise.reject('错误')
//     }
//     // return response;
// }, error => {
//     return Promise.reject(error)
// })

// export default axios