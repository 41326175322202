import axios from '@/utils/request'

// 应用管理接口

// 分页查询
const search = params =>{
    return axios.get('/aiot-api-service/device-app-auth', {params: params})
}

// 新增
const create = params =>{
    return axios.post('/aiot-api-service/device-app-auth', params)
}

// 查看详细
const show = id =>{
    return axios.get(`/aiot-api-service/device-app-auth/${id}`)
}

// 修改
const update = (id, params) =>{
    return axios.put(`/aiot-api-service/device-app-auth/${id}`, params)
}

// 查询所有列表
const all = params => {
    return axios.get('/aiot-api-service/device-app-auth/all', { params: params });
}

// 导出
const exports = params =>{
    return axios.get('/aiot-api-service/device-app-auth/export', {params: params})
}

// 导入
const imports = params =>{
    return axios.post('/aiot-api-service/device-app-auth/import', params)
}

// 删除
const destroy = id => {
    return axios.delete(`/aiot-api-service/device-app-auth/${id}`);
}

// 批量授权
const batchAuthorize = params=> {
    return axios.post(`/aiot-api-service/device-app-auth/batch-create`, params);
}
export default {
    search,
    create,
    show,
    update,
    all,
    exports,
    imports,
    destroy,
    batchAuthorize
}