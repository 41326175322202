import axios from '@/utils/request'
import qs from 'qs'
// 设备管理接口


// 分页查询
const search = params => {
    return axios.get('/aiot-api-service/device?' + qs.stringify(params, { allowDots: true }))
}

// 新增
const create = params => {
    return axios.post('/aiot-api-service/device', params)
}

// 查看详细
const show = (id) => {
    return axios.get(`/aiot-api-service/device/${id}`)
}

// 修改
const update = (id, params) => {
    return axios.put(`/aiot-api-service/device/${id}`, params)
}

// 查询所有列表
const findAll = params => {
    return axios.get('/aiot-api-service/device/all', { params: params });
}

// 导出
const exports = params => {
    return axios.get('/aiot-api-service/device/export', { params: params })
}

// 导入
const imports = params => {
    return axios.post('/aiot-api-service/device/import', params)
}

// 删除
const destroy = id => {
    return axios.delete(`/aiot-api-service/device/${id}`);
}

export default {
    search,
    create,
    show,
    update,
    findAll,
    exports,
    imports,
    destroy
}