import axios from '@/utils/request'

// 应用管理接口

// 分页查询
const search = params => {
    return axios.get('/aiot-api-service/device-group', { params: params })
}

// 新增
const create = params => {
    return axios.post('/aiot-api-service/device-group', params)
}

// 查看详细
const show = id => {
    return axios.get(`/aiot-api-service/device-group/${id}`)
}

// 修改
const update = (id, params) => {
    return axios.put(`/aiot-api-service/device-group/${id}`, params)
}

// 查询所有列表
const all = params => {
    return axios.get('/aiot-api-service/device-group/all', { params: params });
}

// 导出
const exports = params => {
    return axios.get('/aiot-api-service/device-group/export', { params: params })
}

// 导入
const imports = params => {
    return axios.post('/aiot-api-service/device-group/import', params)
}

// 删除
const destroy = id => {
    return axios.delete(`/aiot-api-service/device-group/${id}`);
}

// 删除分组下的设备
const removeDevice = (groupId, deviceId) => {
    return axios.delete(`/aiot-api-service/device-group/${groupId}/device/${deviceId}`);
}
// 添加设备至分组
const addDevices = (id, params) => {
    return axios.post(`/aiot-api-service/device-group/${id}/add`, params)
}

export default {
    search,
    create,
    show,
    update,
    all,
    exports,
    imports,
    destroy,
    removeDevice,
    addDevices
}