import axios from "@/utils/request"

// 登录接口线上
// const login = params => {
//     return axios.get('https://commerce.gloryai.com/passport/oauth/token', {params:params});
// }

// const resetPassword = params =>{
//     return axios.post('https://commerce.gloryai.com/passport/auth/reset', params);
// }

// const getCurrentUser = ()=>{
//     return axios.get('https://commerce.gloryai.com/passport/user')
// }

// 登录测试环境
const login = params => {
    return axios.get('passport/oauth/token', {params:params});
}

const resetPassword = params =>{
    return axios.post('passport/auth/reset', params);
}

const getCurrentUser = ()=>{
    return axios.get('passport/user')
}

export default {
    login,
    resetPassword,
    getCurrentUser
}

