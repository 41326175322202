import axios from '@/utils/request'

// 产品管理接口

// 分页查询
const search = params =>{
    return axios.get('/aiot-api-service/product', {params: params})
}

// 新增
const create = params =>{
    return axios.post('/aiot-api-service/product', params)
}

// 查看详细
const show = (id, params) =>{
    return axios.get(`/aiot-api-service/product/${id}`, {params: params})
}

// 修改
const update = (id, params) =>{
    return axios.put(`/aiot-api-service/product/${id}`, params)
}

// 查询所有列表
const all = params => {
    return axios.get('/aiot-api-service/product/all', { params: params });
}

// 导出
const exports = params =>{
    return axios.get('/aiot-api-service/product/export', {params: params})
}

// 查询厂商某型号的产品
const model = params =>{
    return axios.get('/aiot-api-service/product/find-brand-model-product', {params: params})
}

// 导入
const imports = params =>{
    return axios.post('/aiot-api-service/product/import', params)
}

// 删除
const destroy = id => {
    return axios.delete(`/aiot-api-service/product/${id}`);
}
export default {
    search,
    create,
    show,
    update,
    all,
    exports,
    model,
    imports,
    destroy
}